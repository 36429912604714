<template>
  <b-card>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <div v-else>
      <div class="d-flex justify-content-between mb-2">
        <div />
        <div>
          <b-dropdown
            id="dropdown-form"
            ref="dropdown"
            class="mr-1"
            right
            no-flip
            variant="secondary"
            text="Фильтр"
          >
            <b-dropdown-form
              class="py-1"
              style="width:300px;"
            >
              <b-form-group
                label="Сотрудник"
                label-for="dropdown-form-name"
                @submit.prevent.stop
              >
                <b-form-select
                  id="executor"
                  v-model="filter.user_id"
                  :options="allUsers"
                  :text-field="'full_name'"
                  :value-field="'id'"
                />
              </b-form-group>
              <b-form-group
                label="Даты"
                label-for="date_range_start"
              >
                <flat-pickr
                  id="date_range_start"
                  v-model="filter.date_range_start"
                  class="form-control"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d', defaultDate: null}"
                />
                <br>
                <flat-pickr
                  id="date_range_end"
                  v-model="filter.date_range_end"
                  class="form-control"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d', defaultDate: null}"
                />
              </b-form-group>
              <!--              <b-form-group-->
              <!--                label="Кол-во на странице"-->
              <!--                label-for="dropdown-form-per-page"-->
              <!--                @submit.prevent.stop-->
              <!--              >-->
              <!--                <b-form-select-->
              <!--                  id="dropdown-form-per-page"-->
              <!--                  v-model="filter.perPage"-->
              <!--                  size="sm"-->
              <!--                  :options="[10, 20, 50, 100, 500]"-->
              <!--                />-->
              <!--              </b-form-group>-->

              <!--              <b-button-group>-->
              <!--                <b-button-->
              <!--                  variant="primary"-->
              <!--                  size="sm"-->
              <!--                  @click="doApplyFilters"-->
              <!--                >-->
              <!--                  Применить-->
              <!--                </b-button>-->
              <!--                <b-button-->
              <!--                  variant="outline-secondary"-->
              <!--                  size="sm"-->
              <!--                  @click="doResetFilters"-->
              <!--                >-->
              <!--                  Сбросить-->
              <!--                </b-button>-->
              <!--              </b-button-group>-->
            </b-dropdown-form>
          </b-dropdown>
        </div>
      </div>
      <div v-if="filter.user_id">
        <b-tabs>
          <b-tab title="Зарплата">
            <table class="table table-hover">
              <tr>
                <td><strong>Дата</strong></td>
                <td><strong>Сумма</strong></td>
                <td><strong>Комментарий</strong></td>
              </tr>
              <tr
                v-for="item in kassa"
                :key="item.id"
              >
                <td>{{ formatDate(item.action_date) }}</td>
                <td>{{ item.sum }}</td>
                <td>{{ item.about }}</td>
              </tr>
              <tfoot>
                <tr>
                  <td />
                  <td>Всего: <strong>{{ kassaSum }}</strong></td>
                  <td />
                </tr>
              </tfoot>
            </table>
          </b-tab>
          <b-tab title="Заказы">
            <table class="table table-hover">
              <tr>
                <td><strong>ID</strong></td>
                <td><strong>Дата</strong></td>
                <td><strong>Сумма работ</strong></td>
                <td><strong>VIN <br> Гос номер</strong></td>
                <td><strong>Управление</strong></td>
              </tr>
              <tr
                v-for="item in orders"
                :key="'o' + item.id"
              >
                <td>
                  <router-link :to="{name: 'orders-update', params: {id: item.id}}">
                    {{ item.id }}
                  </router-link>
                </td>
                <td>{{ formatDate(item.updated_at) }}</td>
                <td>{{ calculateWorkSum(item.works_data) }}</td>
                <td>{{ item.auto_info_vin }}<br>
                  {{ item.auto_info_gos_nomer }}
                </td>
                <td>
                  <router-link :to="{name: 'orders-update', params: {id: item.id}}">
                    <b-button
                      variant="flat-warning"
                      class="btn-icon"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        class="text-success"
                      />
                    </b-button>
                  </router-link>
                </td>
              </tr>
              <tfoot>
                <tr>
                  <td />
                  <td>Всего: <strong>{{ worksSum }}</strong></td>
                  <td />
                </tr>
              </tfoot>
            </table>
          </b-tab>
        </b-tabs>
      </div>
      <div v-else>
        <h1>Выберете сотрудника.</h1>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
  BSpinner,
  BFormGroup,
  BDropdown,
  BDropdownForm,
  BButton,
  // BButtonGroup,
  BFormSelect,
  BTabs,
  BTab,
  // BFormInput,
} from 'bootstrap-vue'
import filter from '@/mixins/filter'
import _ from 'lodash'
// import Vue from 'vue'
// import store from '@/store'
import store from '@/store'
import axiosIns from '@/libs/axios'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BCard,
    BSpinner,
    BFormGroup,
    BDropdown,
    BTabs,
    BTab,
    BDropdownForm,
    BButton,
    // BButtonGroup,
    BFormSelect,
    // BFormInput,
  },
  mixins: [filter],
  data() {
    return {
      organizations: null,
      loading: true,
      filter: {},
      allUsers: [],
      kassa: {},
      orders: {},
    }
  },
  computed: {
    kassaSum() {
      return _.reduce(this.kassa, (sum, n) => sum + Number(n.sum), 0)
    },
    worksSum() {
      return _.reduce(this.orders, (sum, n) => {
        let sumWorkAll = 0
        const getAllWorkSum = n.works_data.items
        getAllWorkSum.forEach(a => {
          // eslint-disable-next-line radix
          sumWorkAll += parseInt(a.sum_work)
        })

        return sum + Number(sumWorkAll)
      }, 0)
    },
  },
  watch: {
    filter: {
      deep: true,
      handler(val) {
        this.loadData(val)
      },
    },
  },
  beforeMount() {
    this.loadAllUsers()
    this.loading = false
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    calculateWorkSum(worksData) {
      let sumWorkAll = 0
      const getAllWorkSum = worksData.items
      getAllWorkSum.forEach(a => {
        // eslint-disable-next-line radix
        sumWorkAll += parseInt(a.sum_work)
      })
      return sumWorkAll
    },
    loadData(val) {
      const req = axiosIns({
        method: 'GET',
        url: '/analytics',
        params: val,
      })

      req.then(response => {
        this.kassa = response.data.data.kassa
        this.orders = response.data.data.orders
      })

      return req
    },
    // eslint-disable-next-line consistent-return
    formatDate(inputDate) {
      const date = new Date(inputDate)
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(date.getTime())) {
        return `${(date.getDate() + 1) < 10 ? `0${date.getDate()}` : date.getDate()}.${(date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)}.${date.getFullYear()}`
      }
    },
    loadAllUsers() {
      const req = axiosIns({
        method: 'GET',
        url: '/users/short-list?without=prog',
      })

      req.then(response => {
        const defaultUsers = [{
          id: null,
          full_name: ' ---------- ',
        }]
        defaultUsers.push(...response.data.data)
        this.allUsers = defaultUsers
      })

      return req
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
